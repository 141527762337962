<template>
  <div class="post" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <div class="row gap-7 gap-lg-12 mb-10">
        <!-- BEFORE VERIFICATION -->
        <div class="card col">
          <div class="card-header">
            <div class="card-title">
              <h2>
                {{
                  isViewOnly ? "Data Sebelumnya" : "Data Pembaharuan Terakhir"
                }}
              </h2>
            </div>
          </div>
          <div class="card-body">
            <div class="fs-3 fw-bold mb-3">Biodata</div>
            <div class="row">
              <div class="col-4">
                <ImageUploader
                  :accept="'image/png, image/jpg, image/jpeg'"
                  :uuid="file_foto_before"
                  @fileUploaded="handleFileUploaded"
                  disabled
                ></ImageUploader>
              </div>
              <div class="col-8">
                <div
                  v-for="(value, key) in currentData"
                  :key="key"
                  class="mb-5"
                >
                  <div v-if="biodataSection.includes(key)">
                    <label for="">{{ value.label }}</label>
                    <div v-if="value.type == 'select'">
                      <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="currentData[key].before"
                        disabled
                      >
                        <option value="">{{ `Pilih ${value.label}` }}</option>
                        <template v-if="value.beforeOptions">
                          <option
                            v-for="(v, k) in value.beforeOptions"
                            :key="k"
                            :value="v.value"
                          >
                            {{ v.label }}
                          </option>
                        </template>
                        <template v-else>
                          <option
                            v-for="(v, k) in selectOptions[key]"
                            :key="k"
                            :value="v.value"
                          >
                            {{ v.label }}
                          </option>
                        </template>
                      </select>
                    </div>
                    <div v-else-if="value.type == 'array'">
                      <div v-for="(v, k) in value.before" :key="k">
                        <input
                          type="text"
                          class="form-control mt-3"
                          v-model="currentData[key].before[k].name"
                          disabled
                        />
                      </div>
                    </div>
                    <input
                      v-else
                      :type="value.type"
                      class="form-control"
                      :value="value.before"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="fs-3 fw-bold mb-3">Sinyalemen</div>

            <div v-for="(value, key) in currentData" :key="key" class="mb-5">
              <div v-if="sinyalementSection.includes(key)">
                <label for="">{{ value.label }}</label>
                <div v-if="value.type == 'select'">
                  <select
                    name=""
                    id=""
                    :class="
                      currentData[key].before != currentData[key].after
                        ? 'form-control is-change'
                        : 'form-control'
                    "
                    disabled
                  >
                    <option value="">{{ value.placeholder }}</option>
                  </select>
                </div>
                <div v-else-if="value.type == 'array'">
                  <div v-for="(v, k) in value.before" :key="k">
                    <input
                      type="text"
                      class="form-control mt-3"
                      v-model="currentData[key].before[k].name"
                      disabled
                    />
                  </div>
                </div>
                <input
                  v-else
                  :type="value.type"
                  class="form-control"
                  :value="value.before"
                  disabled
                />
              </div>
            </div>
            <hr />

            <template v-if="loadHonorComponent">
              <InputArray
                ref="honorBefore"
                label="Kehormatan"
                :preData="honorBefore"
                :disabled="true"
                :inputable="false"
              ></InputArray>
            </template>

            <button
              v-if="!isViewOnly"
              class="mt-10 btn btn-secondary"
              @click="$router.back()"
            >
              Kembali
            </button>

            <!-- <PersonForm
            ref="personForm"
            :editable="false"
            :personId="this.$route.params.personId"
            :currentData="currentData"
          ></PersonForm> -->
          </div>
        </div>

        <!-- AFTER VERIFICATION -->
        <div class="card col">
          <div class="card-header">
            <div class="card-title">
              <h2>
                {{ isViewOnly ? "Data Perubahan" : "Pembaharuan Data" }}
              </h2>
            </div>
          </div>
          <div class="card-body">
            <div class="fs-3 fw-bold mb-3">Biodata</div>
            <div class="row">
              <div class="col-4">
                <ImageUploader
                  :accept="'image/png, image/jpg, image/jpeg'"
                  :uuid="file_foto_after"
                  @fileUploaded="handleFileUploaded"
                  :disabled="isViewOnly"
                ></ImageUploader>
              </div>
              <div class="col-8">
                <div
                  v-for="(value, key) in currentData"
                  :key="key"
                  class="mb-5"
                >
                  <div v-if="biodataSection.includes(key)">
                    <label for="">{{ value.label }}</label>
                    <div v-if="value.type == 'select'">
                      <select
                        name=""
                        id=""
                        :class="
                          currentData[key].before != currentData[key].after
                            ? 'form-control is-change'
                            : 'form-control'
                        "
                        v-model="currentData[key].after"
                        :disabled="value.disable"
                      >
                        <option value="">{{ `Pilih ${value.label}` }}</option>
                        <template v-if="value.afterOptions">
                          <option
                            v-for="(v, k) in value.afterOptions"
                            :key="k"
                            :value="v.value"
                          >
                            {{ v.label }}
                          </option>
                        </template>
                        <template v-else>
                          <option
                            v-for="(v, k) in selectOptions[key]"
                            :key="k"
                            :value="v.value"
                          >
                            {{ v.label }}
                          </option>
                        </template>
                      </select>
                    </div>
                    <div v-else-if="value.type == 'array'">
                      <div v-for="(v, k) in value.after" :key="k">
                        <input
                          type="text"
                          :class="
                            currentData[key].before[k].name !=
                            currentData[key].after[k].name
                              ? 'form-control mt-3 is-change'
                              : 'form-control mt-3'
                          "
                          v-model="currentData[key].after[k].name"
                        />
                      </div>
                    </div>
                    <input
                      v-else
                      :type="value.type"
                      :class="
                        currentData[key].before != currentData[key].after
                          ? 'form-control is-change'
                          : 'form-control'
                      "
                      :disabled="value.disable"
                      v-model="currentData[key].after"
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="fs-3 fw-bold mb-3">Sinyalemen</div>

            <div v-for="(value, key) in currentData" :key="key" class="mb-5">
              <div v-if="sinyalementSection.includes(key)">
                <label for="">{{ value.label }}</label>
                <div v-if="value.type == 'select'">
                  <select
                    name=""
                    id=""
                    :class="
                      currentData[key].before != currentData[key].after
                        ? 'form-control is-change'
                        : 'form-control'
                    "
                    v-model="currentData[key].after"
                    :disabled="value.disable"
                  >
                    <option value="">{{ `Pilih ${value.label}` }}</option>
                    <option
                      v-for="(v, k) in selectOptions[key]"
                      :key="k"
                      :value="v.value"
                    >
                      {{ v.label }}
                    </option>
                  </select>
                </div>
                <div v-else-if="value.type == 'array'">
                  <div v-for="(v, k) in value.after" :key="k">
                    <input
                      type="text"
                      :class="
                        currentData[key].before[k].name !=
                        currentData[key].after[k].name
                          ? 'form-control mt-3 is-change'
                          : 'form-control mt-3'
                      "
                      v-model="currentData[key].after[k].name"
                    />
                  </div>
                </div>
                <input
                  v-else
                  :type="value.type"
                  :class="
                    currentData[key].before != currentData[key].after
                      ? 'form-control is-change'
                      : 'form-control'
                  "
                  :disabled="value.disable"
                  v-model="currentData[key].after"
                />
              </div>
            </div>

            <hr />

            <template v-if="loadHonorComponent">
              <InputArray
                ref="honorAfter"
                label="Kehormatan"
                :preData="honorAfter"
                @changeValue="hanldeChangeValue"
                @addInput="handleAddInput"
                @removeInput="handleRemoveInput"
                :disabled="isViewOnly"
                :inputable="!isViewOnly"
              ></InputArray>
            </template>

            <div class="row mt-10" v-if="!isViewOnly">
              <div class="col"></div>
              <div class="col-auto">
                <button class="btn btn-primary" @click="confirmSubmit">
                  Submit Verifikasi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="kt_content_container" class="container-xxl">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Riwayat perubahan data</h2>
          </div>
        </div>
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">User</th>
                <th scope="col">Waktu Perubahan</th>
                <th scope="col">Info</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, k) in auditData" :key="k">
                <td>
                  {{ v.user ? `${v.user.username} - ${v.user.email}` : "-" }}
                </td>
                <td>{{ `${v.createdAt}` }}</td>
                <td>{{ `${v.method} | ${v.ip_address}` }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.is-change {
  border: 2px solid red !important;
  background-color: yellow !important;
}
</style>
<script>
import { toRaw } from "vue";
import ImageUploader from "@/components/forms/ImageUploader.vue";
import InputArray from "@/components/forms/InputArray.vue";

import Service from "@/services/base.service";

export default {
  props: {
    initialData: {
      default: null,
    },
  },
  components: {
    ImageUploader,
    InputArray,
  },
  data() {
    return {
      currentRawData: {},
      currentData: {},
      updatedData: {},
      selectOptions: {},
      file_foto_before: null,
      file_foto_after: null,
      biodataSection: [
        "nip_nrp",
        "nama_lengkap",
        "unity",
        "rank",
        "position",
        "dimension",
      ],
      sinyalementSection: [
        "tinggi",
        "berat",
        "rambut",
        "mata",
        "gol_darah",
        "tempat_lahir",
        "tanggal_lahir",
        "agama",
        "kontak_darurat",
        "alamat",
      ],
      hiddenInput: [
        "unityId",
        "rankId",
        "positionId",
        "dimensionId",
        "createdAt",
        "updatedAt",
        "deletedAt",
        "parent_id",
        "file_foto",
        "card_applications",
        "attachments",
        "person",
        "status",
        "id",
        // "honors",
        "period_id",
      ],
      honorSection: ["honors"],
      auditData: [],
      honorBefore: [],
      honorAfter: [],
      loadHonorComponent: false,
      isVerified: false,
      isViewOnly: false,
    };
  },
  mounted() {
    this.isViewOnly = this.$route.name == "audit";
    this.init();
  },
  watch: {},
  methods: {
    init() {
      if (this.$route.name != "audit") {
        this.getCurrentData();
        this.fetchChangeHistory();
      } else {
        this.fetchAuditView();
      }
    },
    inputType(key) {
      const selectInput = ["unity", "rank", "position", "dimension"];
      const numberInput = ["tinggi", "berat"];
      const arrayInput = ["honors"];

      if (selectInput.includes(key)) {
        return "select";
      }
      if (numberInput.includes(key)) {
        return "number";
      }
      if (arrayInput.includes(key)) {
        return "array";
      }

      return "text";
    },
    parseLabel(key) {
      const labels = {
        nip_nrp: "NIP/NRP",
        nama_lengkap: "Nama Lengkap",
        tinggi: "Tinggi",
        berat: "Berat",
        rambut: "Rambut",
        mata: "Mata",
        gol_darah: "Golongan Darat",
        tempat_lahir: "Tempat Lahir",
        tanggal_lahir: "Tanggal Lahir",
        agama: "Agama",
        alamat: "Alamat",
        kontak_darurat: "Kontak Darurat",
        nama_pasangan: "Nama Pasangan",
        nama_ortu: "Nama Orang Tua",
        tempat_kawin: "Tempat Kawin",
        no_surat_kawin: "No Surat Kawin",
        status: "UPLOADED",
        unity: "Kesatuan",
        rank: "Pangkat",
        position: "Jabatan",
        dimension: "Matra",
        honors: "Kehormatan",
      };
      return labels[key] ? labels[key] : key;
    },
    async fetchAuditView() {
      const { id } = this.$route.params;
      if (!id) {
        alert("invalid url");
      }
      const BaseService = new Service("audit/view");
      const { data } = await BaseService.getData(id);
      const keys = Object.keys(data.before);
      keys.forEach((key) => {
        if (!this.hiddenInput.includes(key)) {
          const type = this.inputType(key);
          this.currentData[key] = {
            label: this.parseLabel(key),
            before:
              data.before[key] && data.before[key].id
                ? data.before[key].id
                : data.before[key],
            beforeOptions:
              data.before[key] && data.before[key].name
                ? [{ value: data.before[key].id, label: data.before[key].name }]
                : [],
            after:
              data.after[key] && data.after[key].id
                ? data.after[key].id
                : data.after[key],
            afterOptions:
              data.after[key] && data.after[key].name
                ? [{ value: data.after[key].id, label: data.after[key].name }]
                : [],

            type,
            disable: true,
          };
        }
      });
      this.honorBefore = data.before.honors.map((m) => m.name);
      this.honorAfter = data.after.honors.map((m) => m.name);
      this.auditData = [data.audit];
      if (data.before.file_foto) {
        this.file_foto_before = data.before.file_foto;
      }
      if (data.after.file_foto) {
        this.file_foto_after = data.after.file_foto;
      }

      this.loadHonorComponent = true;
    },
    async fetchMasterOption() {
      const matraService = new Service("master/matra");
      const pangkatService = new Service("master/pangkat");
      const jabatanService = new Service("master/jabatan");
      const kesatuanService = new Service("master/kesatuan");

      const mergeRequest = [
        matraService.getListData(),
        pangkatService.getListData(),
        jabatanService.getListData(),
        kesatuanService.getListData(),
      ];

      const [matraData, pangkatData, jabatanData, kesatuanData] =
        await Promise.all(mergeRequest);

      this.selectOptions["dimension"] = matraData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
      this.selectOptions["rank"] = pangkatData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
      this.selectOptions["position"] = jabatanData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
      this.selectOptions["unity"] = kesatuanData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
    },
    async getCurrentData() {
      const disableInput = ["id"];

      const id = this.$route.params.personId;
      const personService = new Service("person");
      try {
        const { data } = await personService.getData(id);
        this.currentRawData = data;
        Object.entries(data).forEach(([key, value]) => {
          if (!this.hiddenInput.includes(key)) {
            this.currentData[key] = {
              label: this.parseLabel(key),
              before: value && value.id ? value.id : value,
              placeholder: value && value.name ? value.name : value,
              after: value && value.id ? value.id : value,
              type: this.inputType(key),
              disable: disableInput.includes(key),
            };
          }
        });

        this.isVerified = data.status.toUpperCase() == "VERIFIED";
        this.honorBefore = data.honors.map((m) => m.name);
        this.honorAfter = data.honors.map((m) => m.name);

        this.fetchChangeHistory(data.id);

        const { file_foto } = data;
        this.file_foto_before = file_foto;
        this.file_foto_after = file_foto;

        this.fetchMasterOption();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadHonorComponent = true;
      }
    },
    postUpdateData() {},
    confirmSubmit() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Yakin data sudah sesuai?",
          // text: "Data yang sudah valid akan dibuat pengajuan pembuatan KTA",
          showCancelButton: true,
          confirmButtonText: "YA",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
          preConfirm: async () => {
            try {
              // TODO: CHECK FILE IS UPLOADED
              const params = {};
              Object.entries(this.currentData).forEach(([key, value]) => {
                params[key] = toRaw(value).after;
              });
              params.status = "VERIFIED";
              const BaseService = new Service("person");
              return await BaseService.updateData(
                this.$route.params.personId,
                params
              );
            } catch (error) {
              console.log(error);
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            const { message } = result.value;
            this.$swal.fire({
              title: message,
            });
            this.$router.go(-1);
          }
        });
    },
    handleFileUploaded(fileUuid) {
      this.currentData.file_foto = {
        after: fileUuid.id,
      };
      // this.currentData.attachments = {
      //   after: [fileUuid],
      // };
    },
    handleChangeValue(value) {
      console.log("handleChangeValue", value);
    },
    async fetchChangeHistory(id) {
      const service = new Service("audit");
      const { data } = await service.getListData({
        model_name: "person",
        model_id: id,
      });
      this.auditData = data;
    },
    loadMetadata(data) {
      const parseJson = JSON.parse(data);
      console.log(parseJson);
    },
    handleAddInput() {
      this.honorAfter.push("");
    },
    handleRemoveInput(index) {
      this.honorAfter.splice(index, 1);
    },
    hanldeChangeValue(value) {
      const data = toRaw(value);
      if (this.currentData.honors && this.currentData.honors.after) {
        this.currentData.honors.after = data;
      }
    },
  },
};
</script>
